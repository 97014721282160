* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Tahoma', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  display: flex;
  align-items: center;

  font-size: 48px;
  font-weight: bold;
  text-decoration: none;
  color: #242222;
}

.link {
  padding: 10px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: #242222;
}

.link:hover {
  color: rgb(56, 56, 141);
  border: 1px solid #242222;
  border-radius: 5px;
  padding: 10px;
}

.a {
  display: block;
}

.img {
  display: block;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-link {
  display: block;
}

.footer-mail {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #242222;
}

.footer-link {
  display: block;
  text-decoration: none;
  margin-left: 20px;
}

.contact-lik {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #242222;
}
